<template>
  <div>
    <button @click="printReceipt">Print Receipt</button>
    <div ref="receipt" class="receipt">
      <h2>Store Name</h2>
      <p>Date: {{ formattedDate }}</p>
      <hr />
      <p v-for="item in items" :key="item.id">
        {{ item.name }} - ${{ item.price }}
      </p>
      <hr />
      <b class="is-flex is-justify-content-center">Ticket Rules</b>
      <hr />
      <p>- Valid for 24 hours from first use</p>
      <p>- Unlimited hop-on/hop-off access</p>
      <p>- Must present this ticket to board</p>
      <p>- Non-transferable</p>

      <hr />
      <b class="is-flex is-justify-content-center">COSTUMER SUPPORT</b>
      <hr />
      <p>CONTACT: +351 920 043 375</p>
      <p>EMAIL: support@guiders.pt</p>
      <p>WEBSITE: www.guiders.pt</p>

      <hr />
      <p class="is-flex is-justify-content-center">
        THANK YOU FOR YOUR PURCHASE
      </p>
      <p class="is-flex is-justify-content-center">ENJOY YOUR TOUR</p>

      <hr />
      <img src="" />

      <p class="is-flex is-justify-content-center">Scan to validate ticket</p>
      <hr />
      <p>Total: ${{ total }}</p>
      <!--  ┌───────────────────────────────────┐
        │         GUIDERS TUK TUK TOUR       │
        │       Hop-On Hop-Off Ticket        │
        ├───────────────────────────────────┤
        │ DATE OF PURCHASE:  13-03-2025      │
        │ TICKET NUMBER:     TTK-123456-1    │
        │ PASSENGER:        John Doe         │
        │ TICKET:           1 of 4           │
        │ TICKET TYPE:      Adult            │
        │ PRICE:           €25.00            │
        │ PAYMENT METHOD:  Credit Card       │
        │ VALIDITY:        24 Hours          │
        ├───────────────────────────────────┤
        │           TICKET RULES              │
        ├───────────────────────────────────┤
        │ - Valid for 24 hours from first use │
        │ - Unlimited hop-on/hop-off access   │
        │ - Must present this ticket to board │
        │ - Non-transferable │
        ├───────────────────────────────────┤
        │           CUSTOMER SUPPORT          │
        ├───────────────────────────────────┤
        │ CONTACT:  +351 912 345 678          │
        │ EMAIL:    info@guiders.pt           │
        │ WEBSITE:  www.guiders.pt            │
        ├───────────────────────────────────┤
        │       THANK YOU FOR YOUR PURCHASE   │
        │       ENJOY YOUR TUK TUK TOUR       │
        ├───────────────────────────────────┤
        │            [ QR CODE ]              │
        │   Scan to validate your ticket      │
        └───────────────────────────────────┘
 -->
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      items: [
        { id: 1, name: "Product A", price: 10 },
        { id: 2, name: "Product B", price: 20 },
      ],
    };
  },
  computed: {
    formattedDate() {
      return new Date().toLocaleString();
    },
    total() {
      return this.items.reduce((sum, item) => sum + item.price, 0);
    },
  },
  methods: {
    async printReceipt() {
        const printContent = this.$refs.receipt.innerHTML;
      const newWindow = window.open("", "_blank");
      newWindow.document.write(
        `<html><head><title>Receipt</title></head><body>${printContent}</body></html>`
      );
      newWindow.document.close();
      newWindow.print();
      newWindow.close(); 
    /*   try {
    console.log("Requesting Bluetooth device...");
    const device = await navigator.bluetooth.requestDevice({
      acceptAllDevices: true,
      optionalServices: ["00001101-0000-1000-8000-00805f9b34fb"] // Replace with your printer's UUID
    });

    console.log("Connecting to GATT server...");
    const server = await device.gatt.connect();

    console.log("Getting service...");
    const service = await server.getPrimaryService("00001101-0000-1000-8000-00805f9b34fb"); // Replace if needed

    console.log("Getting characteristic...");
    const characteristic = await service.getCharacteristic("00002a37-0000-1000-8000-00805f9b34fb"); // Replace if needed

    console.log("Sending data...");
    const encoder = new TextEncoder();
    const escPosData = encoder.encode("Hello Printer\n"); // Modify with ESC/POS commands if needed

    await characteristic.writeValue(escPosData);

    console.log("Print success!");
  } catch (error) {
    console.error("Bluetooth Print Error:", error);
  } */
    }
  },
};
</script>

<style>
.receipt {
  font-family: monospace;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #000;
}
</style>
